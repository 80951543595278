// LogoutButton.js
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './App';
import './LogoutButton.css';

const LogoutButton = () => {
  const { logout, deployments } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isDarkMode, setIsDarkMode] = useState(false);
  
  // Determine if we're in dark mode based on the current route
  useEffect(() => {
    const currentPath = window.location.pathname.substring(1); // Remove leading slash
    
    // Find the deployment that matches this path
    let matchingDeployment = null;
    Object.values(deployments || {}).forEach(deployment => {
      if (deployment.FORWARDSLASH === currentPath) {
        matchingDeployment = deployment;
      }
    });
    
    // Set dark mode based on deployment settings
    if (matchingDeployment) {
      setIsDarkMode(!!matchingDeployment.DARK);
    }
  }, [deployments]);
  
  const handleLogout = () => {
    logout();
    // Redirect to login page after logout
    navigate('/login', { replace: true });
  };
  
  return (
    <button 
      className={`logout-button ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
      onClick={handleLogout}
      title="Sign Out"
    >
      <i className="logout-icon">⎋</i>
      <span>Sign Out</span>
    </button>
  );
};

export default LogoutButton;