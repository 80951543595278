// ForgotPasswordPage.js
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './App';
import { API_BASE_URL } from './App';
import './LoginPage.css'; // Reuse the login page CSS

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [deploymentTitle, setDeploymentTitle] = useState('Vector Vault');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { deployments } = useContext(AuthContext);
  const navigate = useNavigate();
  
  // Get the redirectPath from sessionStorage - don't reset it when loading this page
  const pageKey = sessionStorage.getItem('intendedPageKey') || '';
  
  // Find the deployment title based on the pageKey
  useEffect(() => {
    if (pageKey && deployments && Object.keys(deployments).length > 0) {
      const deployment = Object.values(deployments).find(d => d.FORWARDSLASH === pageKey);
      if (deployment && deployment.TITLE) {
        setDeploymentTitle(deployment.TITLE);
        console.log(`Found deployment title for ${pageKey}: ${deployment.TITLE}`);
      } else {
        console.log(`No deployment title found for ${pageKey}`);
      }
    }
  }, [pageKey, deployments]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setMessage('');
    
    try {
      // Get rootUser from window.env
      const rootUser = window.env && window.env.USER;
      console.log('Forgot password request for:', email, 'pageKey:', pageKey, 'rootUser:', rootUser);
      
      const response = await fetch(`${API_BASE_URL}/forgot_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email, 
          pageKey,
          rootUser  // Send the root user
        }),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to process request');
      }
      
      // Always show success message even if email doesn't exist (for security)
      setMessage('If your email exists in our system, you will receive a password reset link shortly.');
    } catch (err) {
      console.error('Forgot password error:', err);
      setError(err.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleBackToLogin = () => {
    // Navigate back to login without changing the intended path/pageKey
    navigate('/login');
  };
  
  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-header">
          <img 
            src="/logo.png" 
            alt="VectorVault Logo" 
            className="login-logo" 
            onError={(e) => e.target.style.display = 'none'} 
          />
          <h1>{deploymentTitle}</h1>
          <p className="login-subtitle">Reset your password</p>
        </div>
        
        {error && (
          <div className="login-error">
            <i className="error-icon">⚠️</i>
            <span>{error}</span>
          </div>
        )}
        
        {message && (
          <div className="login-success">
            <i className="success-icon">✓</i>
            <span>{message}</span>
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <div className="input-container">
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ textAlign: 'center' }}
              />
            </div>
          </div>
          
          <button 
            type="submit" 
            className={`login-button ${loading ? 'loading' : ''}`} 
            disabled={loading}
          >
            {loading ? (
              <span className="loader-text">
                <span className="loader"></span>
                Sending...
              </span>
            ) : (
              'Send Reset Link'
            )}
          </button>
          
          <div className="form-links">
            <button 
              type="button" 
              className="text-button" 
              onClick={handleBackToLogin}
            >
              Back to Login
            </button>
          </div>
        </form>
        
        <div className="login-footer">
          <p>Powered by <a href="https://vectorvault.io" target="_blank" rel="noopener noreferrer">Vector Vault</a></p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;