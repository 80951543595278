// ResetPasswordPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { API_BASE_URL } from './App';
import './LoginPage.css'; // Reuse the login page CSS

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [tokenValid, setTokenValid] = useState(false);
  const [validating, setValidating] = useState(true);
  
  const navigate = useNavigate();
  const location = useLocation();
  
  // Extract token and pageKey from URL query params
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenParam = queryParams.get('token');
    
    if (!tokenParam) {
      setError('Invalid or missing reset token');
      setValidating(false);
      return;
    }
    
    setToken(tokenParam);
    
    // Validate the token
    const validateToken = async () => {
      try {
        await fetch(`${API_BASE_URL}/validate_reset_token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            token: tokenParam
          }),
        });
        
        setTokenValid(true);
      } catch (err) {
        console.error('Token validation error:', err);
        setError('This password reset link is invalid or has expired');
        setTokenValid(false);
      } finally {
        setValidating(false);
      }
    };
    
    validateToken();
  }, [location.search]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    
    // Check password strength
    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      const response = await fetch(`${API_BASE_URL}/reset_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          token,
          newPassword: password
        }),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to reset password');
      }
      
      setMessage('Your password has been successfully reset.');
      
      // Redirect to destination URL or login after a short delay
      setTimeout(() => {
        if (data.destinationUrl) {
          window.location.href = data.destinationUrl; // Use window.location for external URLs
        } else {
          navigate('/login');
        }
      }, 3000);
    } catch (err) {
      console.error('Password reset error:', err);
      setError(err.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleBackToLogin = () => {
    navigate('/login');
  };
  
  // Show loading state while validating token
  if (validating) {
    return (
      <div className="login-page">
        <div className="login-container">
          <div className="loading-container">
            <div className="loading-spinner"></div>
            <p>Validating reset link...</p>
          </div>
        </div>
      </div>
    );
  }
  
  // If token is invalid, show error message
  if (!tokenValid && !validating) {
    return (
      <div className="login-page">
        <div className="login-container">
          <div className="login-header">
            <img 
              src="/logo.png" 
              alt="VectorVault Logo" 
              className="login-logo" 
              onError={(e) => e.target.style.display = 'none'} 
            />
            <h1>Reset Password</h1>
          </div>
          
          <div className="login-error">
            <i className="error-icon">⚠️</i>
            <span>{error}</span>
          </div>
          
          <button 
            className="login-button"
            onClick={handleBackToLogin}
          >
            Back to Login
          </button>
        </div>
      </div>
    );
  }
  
  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-header">
          <img 
            src="/logo.png" 
            alt="VectorVault Logo" 
            className="login-logo" 
            onError={(e) => e.target.style.display = 'none'} 
          />
          <h1>Reset Password</h1>
          <p className="login-subtitle">Enter your new password</p>
        </div>
        
        {error && (
          <div className="login-error">
            <i className="error-icon">⚠️</i>
            <span>{error}</span>
          </div>
        )}
        
        {message && (
          <div className="login-success">
            <i className="success-icon">✓</i>
            <span>{message}</span>
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="password">New Password</label>
            <div className="input-container">
              <input
                type="password"
                id="password"
                placeholder="Enter new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ textAlign: 'center' }}
              />
            </div>
          </div>
          
          <div className="form-group">
            <label htmlFor="confirm-password">Confirm Password</label>
            <div className="input-container">
              <input
                type="password"
                id="confirm-password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                style={{ textAlign: 'center' }}
              />
            </div>
          </div>
          
          <button 
            type="submit" 
            className={`login-button ${loading ? 'loading' : ''}`} 
            disabled={loading}
          >
            {loading ? (
              <span className="loader-text">
                <span className="loader"></span>
                Resetting...
              </span>
            ) : (
              'Reset Password'
            )}
          </button>
        </form>
        
        <div className="login-footer">
          <p>Powered by <a href="https://vectorvault.io" target="_blank" rel="noopener noreferrer">Vector Vault</a></p>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;