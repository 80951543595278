// ProtectedRoute.js
import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from './App'; // Import from App.js
import './Loading.css';

export const ProtectedRoute = ({ children, pageKey, isPublic = false }) => {
  const { currentUser, hasPageAccess, loading, deployments } = useContext(AuthContext);
  const location = useLocation();
  
  // Check if deployments are loaded - we need to ensure we have data to determine access
  const deploymentsLoaded = Object.keys(deployments).length > 0;
  
  // console.log('ProtectedRoute check for:', pageKey, {
  //   isPublic,
  //   currentUser: !!currentUser,
  //   hasAccess: hasPageAccess(pageKey),
  //   loading,
  //   deploymentsLoaded,
  //   pathname: location.pathname
  // });
  
  if (loading || !deploymentsLoaded) {
    return <div className="deploy-loading-container">
      <div className="deploy-loading-spinner"></div>
      <p>{loading ? 'Loading authentication...' : 'Loading deployments...'}</p>
    </div>;
  }
  
  // If the page is public, allow access without authentication
  if (isPublic) {
    // console.log('Public page, allowing access to:', pageKey);
    return children;
  }
  
  if (!currentUser) {
    // console.log('No user, redirecting to login with state:', {
    //   from: location.pathname,
    //   pageKey
    // });
    // Redirect to login if not logged in, save the page they were trying to access
    return <Navigate 
      to="/login" 
      state={{ 
        from: location.pathname, 
        pageKey 
      }} 
      replace 
    />;
  }
  
  if (!hasPageAccess(pageKey)) {
    // console.log('User does not have access to page:', pageKey);
    // Redirect to unauthorized page if they don't have access to this specific page
    return <Navigate to="/unauthorized" replace />;
  }
  
  // console.log('Access granted to:', pageKey);
  return children;
};