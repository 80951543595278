// DebugPanel.js
import React from 'react';

/**
 * A debug panel component that shows development information
 * Only appears in development mode
 */
const DebugPanel = ({ deployments, wait }) => {
  if (process.env.NODE_ENV !== 'development') {
    return null;
  }
  
  return (
    <div style={{
      position: 'fixed',
      bottom: 0,
      right: 0,
      background: 'rgba(0,0,0,0.7)',
      color: 'white',
      padding: '5px',
      fontSize: '10px',
      borderTopLeftRadius: '5px',
      zIndex: 1000
    }}>
      Path: {window.location.pathname} | 
      Deployments: {Object.keys(deployments).length} |
      Wait: {wait.toString()}
    </div>
  );
};

export default DebugPanel;