// AuthProvider.js
import React, { useState, useEffect } from 'react';
import { AuthContext, API_BASE_URL } from './App'; // Import AuthContext from App.js

export const AuthProvider = ({ children, vectorVaultRef }) => {
  // console.log('AuthProvider is rendering with vectorVaultRef:', vectorVaultRef);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deployments, setDeployments] = useState({});

  // Load user from local storage on initial render
  useEffect(() => {
    const storedUser = localStorage.getItem('currentUser');
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  // Login function
  const login = async (email, password, pageKey) => {
    try {
      setLoading(true);
      setError(null);
      
      // Get rootUser from window.env
      const rootUser = window.env && window.env.USER;
      // console.log('Login with rootUser:', rootUser);
      
      // Use the verify_user endpoint with the rootUser parameter
      const response = await fetch(`${API_BASE_URL}/verify_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email, 
          password, 
          pageKey,
          rootUser  // Include the rootUser in the request
        }),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }
      
      // Store user info with authorized pages
      const user = {
        id: data.user.id,
        email: data.user.email,
        name: data.user.name,
        authorizedPages: data.user.authorizedPages, // Array of page keys the user can access
      };
      
      setCurrentUser(user);
      localStorage.setItem('currentUser', JSON.stringify(user));
      return user;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Logout function
  const logout = () => {
    setCurrentUser(null);
    localStorage.removeItem('currentUser');
  };

  // Check if user has access to a specific page
  const hasPageAccess = (pageKey) => {
    // If we don't have deployments data yet, we can't determine access
    if (Object.keys(deployments).length === 0) {
      // console.log(`No deployments data available yet for page ${pageKey}`);
      // Default to false for safety
      return false;
    }

    // Find the deployment for this page key
    const deployment = Object.values(deployments).find(d => d.FORWARDSLASH === pageKey);
    
    // If no deployment is found for this pageKey, deny access
    if (!deployment) {
      // console.log(`No deployment found for page ${pageKey}`);
      return false;
    }
    
    // Convert IS_PUBLIC to boolean if it's a string
    let isPublic = deployment.IS_PUBLIC;
    
    // String conversion - handle "false", "true" strings
    if (typeof isPublic === 'string') {
      isPublic = isPublic.toLowerCase() === 'true';
    }
    
    // If this is a public page, everyone has access
    // Check IS_PUBLIC first, and if not present, check if AUTH_USERS or USERS exist
    // If IS_PUBLIC is explicitly true, or if IS_PUBLIC is undefined/null and 
    // there are no AUTH_USERS or USERS arrays, consider it public
    if (
      isPublic === true || 
      (isPublic === undefined && 
       !deployment.AUTH_USERS && 
       !deployment.USERS)
    ) {
      // console.log(`Page ${pageKey} is public`);
      return true;
    }
    
    // Otherwise, check user's authorized pages
    if (!currentUser) {
      // console.log(`No current user for protected page ${pageKey}`);
      return false;
    }
    
    const hasAccess = currentUser.authorizedPages.includes(pageKey);
    // console.log(`User access to ${pageKey}: ${hasAccess}`);
    return hasAccess;
  };

  const authContextValue = {
    currentUser,
    loading,
    error,
    login,
    logout,
    hasPageAccess,
    deployments,
    setDeployments,
    vectorVault: vectorVaultRef.current, // Include vectorVault in the context
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};