// useDeployments.js
import { useCallback, useState, useContext } from 'react';
import { postMail } from './api/credationStation';
import { AuthContext } from './App';

/**
 * Custom hook to handle deployments fetching and initialization
 * @param {object} vectorVaultRef - Reference to VectorVault instance
 * @returns {object} - Object containing deployment state and handlers
 */
const useDeployments = (vectorVaultRef) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [wait, setWait] = useState(true);
  
  const { setDeployments } = useContext(AuthContext);

  /**
   * Fetches deployments and initializes the targeted deployment
   * @param {string} user - The encrypted user identifier
   */
  const handleCredGet = useCallback(async (user) => {
    try {
      setWait(true);
      // console.log('handleCredGet called', { user });
      
      // Default route - redirect to login
      if (!user) {
        // console.log('No user found, should redirect to login');
        setWait(false);
        return;
      }
      
      // Get deployments regardless of login status
      // console.log('Making postMail API call');
      const result = await postMail(user);
      // console.log('API response received');
      
      if (result && result.deployments) {
        // console.log('Deployments found, updating context');
        
        // Update deployments in the AuthContext
        setDeployments(result.deployments);
        
        const currentPath = window.location.pathname.substring(1);
        // console.log('Current path:', currentPath);
        
        const targetDeployment = Object.values(result.deployments).find(d => 
          currentPath === d.FORWARDSLASH
        );
        
        if (targetDeployment) {
          // console.log('Found target deployment:', targetDeployment.TITLE);
          setTitle(targetDeployment.TITLE);
          setDescription(targetDeployment.DESCRIPTION);
          
          // Enhanced vectorVault validation and initialization
          // console.log('VectorVault ref check:', {
          //   hasRef: !!vectorVaultRef,
          //   hasCurrent: !!(vectorVaultRef && vectorVaultRef.current),
          //   hasInitMethod: !!(vectorVaultRef && 
          //                    vectorVaultRef.current && 
          //                    typeof vectorVaultRef.current.initializeDeployment === 'function')
          // });
          
          // Check if vectorVaultRef exists and has initializeDeployment method
          if (vectorVaultRef && 
              vectorVaultRef.current && 
              typeof vectorVaultRef.current.initializeDeployment === 'function') {
            try {
              // console.log('Initializing VectorVault with:', {
              //   user,
              //   title: targetDeployment.TITLE
              // });
              
              await vectorVaultRef.current.initializeDeployment(user, targetDeployment.TITLE);
              // console.log('VectorVault initialized successfully');
            } catch (vvError) {
              console.error('Error initializing VectorVault:', vvError);
            }
          } else {
            console.error('VectorVault reference is invalid or missing initializeDeployment method');
          }
        } else {
          // console.log('No matching deployment found for path:', currentPath);
        }
      } else {
        console.error('Invalid response format or no deployments found');
      }
      
      setWait(false);
    } catch (error) {
      console.error('Error in handleCredGet:', error);
      setWait(false);
    }
  }, [setDeployments, vectorVaultRef]);

  return {
    title,
    description,
    wait,
    handleCredGet
  };
};

export default useDeployments;