// Unauthorized.js
import React from 'react';
import { Link } from 'react-router-dom';

const Unauthorized = () => {
  return (
    <div style={{ 
      maxWidth: '500px', 
      margin: '100px auto', 
      padding: '30px', 
      textAlign: 'center',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      borderRadius: '5px',
      backgroundColor: '#fff'
    }}>
      <h2 style={{ marginBottom: '20px', color: '#dc3545' }}>Unauthorized Access</h2>
      <p style={{ marginBottom: '25px', fontSize: '16px' }}>
        You don't have permission to view this page.
      </p>
      <Link 
        to="/login" 
        style={{
          display: 'inline-block',
          padding: '10px 20px',
          backgroundColor: '#007bff',
          color: 'white',
          textDecoration: 'none',
          borderRadius: '4px',
          fontWeight: 'bold'
        }}
      >
        Back to Login
      </Link>
    </div>
  );
};

export default Unauthorized;