// App.js
import React, { createContext, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import VectorVault from 'vectorvault';
import { AuthProvider } from './AuthProvider';
import AppContent from './AppContent';

// Define AuthContext directly in App.js (but provider is in separate file)
export const AuthContext = createContext(null);

// API Base URL - Make sure this is the absolute URL
export const API_BASE_URL = 'https://deploy.vectorvault.io';

// Main App component
function App() {
  // console.log('App component is rendering');
  
  // Create the VectorVault instance at the top level
  const vectorVaultRef = useRef(new VectorVault());
  
  return (
    <BrowserRouter>
      <AuthProvider vectorVaultRef={vectorVaultRef}>
        <AppContent vectorVaultRef={vectorVaultRef} />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;