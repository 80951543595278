// useNavigationHandler.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Custom hook to capture and store the user's intended path
 */
const useNavigationHandler = () => {
  const location = useLocation();
  
  useEffect(() => {
    // Don't store login or unauthorized paths
    if (
      location.pathname && 
      location.pathname !== '/login' && 
      location.pathname !== '/unauthorized' && 
      location.pathname !== '/forgot-password' && 
      location.pathname !== '/reset-password' 
    ) {
      // Store the current path for redirects after login
      sessionStorage.setItem('intendedPath', location.pathname);
      
      // Extract pageKey from the path (remove leading '/')
      const pageKey = location.pathname.substring(1);
      sessionStorage.setItem('intendedPageKey', pageKey);
      
      console.log('Navigation handler: Stored intended path:', location.pathname);
    }
  }, [location.pathname]);
};

export default useNavigationHandler;