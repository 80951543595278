// AppRoutes.js
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import LoginPage from './LoginPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import ResetPasswordPage from './ResetPasswordPage';
import Unauthorized from './Unauthorized';
import Dashboard from './dashboard/Dashboard';
import Database from './dashboard/Database.js';
import { darkStyles, lightStyles } from './styles.js';

/**
 * Component that handles all the application routes
 */
const AppRoutes = ({ deployments, wait }) => {
  return (
    <Routes>
      {/* Explicit routes */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      
      {/* Dynamic routes based on deployments */}
      {Object.entries(deployments).map(([key, deployment]) => {
        // Convert IS_PUBLIC to boolean if it's a string
        let isPublic = deployment.IS_PUBLIC;
        
        // String conversion - handle "false", "true" strings
        if (typeof isPublic === 'string') {
          isPublic = isPublic.toLowerCase() === 'true';
          // console.log(`Route ${deployment.FORWARDSLASH}: Converted IS_PUBLIC string "${deployment.IS_PUBLIC}" to boolean: ${isPublic}`);
        }
        
        // Determine if the deployment is public
        // Consider it public if IS_PUBLIC is true or if there are no AUTH_USERS/USERS
        isPublic = 
          isPublic === true || 
          (isPublic === undefined && 
           !deployment.AUTH_USERS && 
           !deployment.USERS);
           
        // console.log(`Route ${deployment.FORWARDSLASH} isPublic:`, isPublic);
        
        return (
          <Route
            key={deployment.FORWARDSLASH}
            path={`/${deployment.FORWARDSLASH}`}
            element={
              <ProtectedRoute 
                pageKey={deployment.FORWARDSLASH} 
                isPublic={isPublic}
              >
                {deployment.CHAT ? (
                  <Dashboard 
                    Ampersandposium={deployment.VAULT}
                    isDarkMode={deployment.DARK}
                    styles={deployment.DARK ? darkStyles : lightStyles}
                    wait={wait}
                    data={deployment.DATA}
                    edit={deployment.EDIT}
                    del={deployment.DELETE}
                    gp4={deployment.GPT4}
                    apiVisible={deployment.APIVISIBLE}
                    model={deployment.MODEL}
                    context={deployment.CONTEXT}
                    description={deployment.DESCRIPTION}
                    title={deployment.TITLE}
                    json={deployment.JSON}
                  />
                ) : (
                  <Database
                    Ampersandposium={deployment.VAULT}
                    isDarkMode={deployment.DARK}
                    styles={deployment.DARK ? darkStyles : lightStyles}
                    wait={wait}
                    edit={deployment.EDIT}
                    del={deployment.DELETE}
                    description={deployment.DESCRIPTION}
                    title={deployment.TITLE}
                    json={deployment.JSON}
                  />
                )}
              </ProtectedRoute>
            }
          />
        );
      })}
      
      {/* Default route - should be last */}
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default AppRoutes;