// AppContent.js 
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './App';
import useTagManager from './hooks/useTagManager';
import useDocumentMeta from './useDocumentMeta';
import useDeployments from './useDeployments';
import useNavigationHandler from './useNavigationHandler';
import AppRoutes from './AppRoutes';
import DebugPanel from './DebugPanel';
import LogoutButton from './LogoutButton';

function AppContent({ vectorVaultRef }) {
//   console.log('AppContent is rendering with vectorVaultRef:', vectorVaultRef);
  
  // Safely access window.env.USER
  const user = window.env && window.env.USER;
  if (!window.env) {
    console.error('window.env is undefined');
  }
  
  const [deploymentsLoaded, setDeploymentsLoaded] = useState(false);
  const [shouldShowLogoutButton, setShouldShowLogoutButton] = useState(false);
  
  // Custom hooks
  useTagManager();
  useNavigationHandler(); 
  
  // Get context values
  const { deployments, currentUser } = useContext(AuthContext);
  
  // Use the vectorVaultRef directly in useDeployments
  const { title, description, wait, handleCredGet } = useDeployments(vectorVaultRef);
  
  // Set document meta information
  useDocumentMeta(title, description);
  
  // Fetch deployments effect
  useEffect(() => {
    // console.log('User effect running with:', { user });
    
    if (user) {
    //   console.log('Calling handleCredGet with user:', user);
      // Wrap handleCredGet in an async function to track when it's complete
      const fetchDeployments = async () => {
        try {
          await handleCredGet(user);
          // Mark deployments as loaded
          setDeploymentsLoaded(true);
        } catch (error) {
          console.error('Error fetching deployments:', error);
          setDeploymentsLoaded(true); // Still mark as loaded even on error
        }
      };
      
      fetchDeployments();
    } else {
    //   console.log('No user available, not calling handleCredGet');
      setDeploymentsLoaded(true); // Mark as loaded if no user
    }
  }, [user, handleCredGet]);
  
  // Determine if we should show the logout button
  useEffect(() => {
    if (!deploymentsLoaded || !currentUser) {
      setShouldShowLogoutButton(false);
      return;
    }
    
    // Get current path
    const currentPath = window.location.pathname.substring(1); // Remove leading slash
    // console.log(`Checking if ${currentPath} is private...`);
    
    // Find the deployment that matches this path by FORWARDSLASH
    let matchingDeployment = null;
    
    // Loop through all deployments to find the one with matching FORWARDSLASH
    Object.values(deployments).forEach(deployment => {
      if (deployment.FORWARDSLASH === currentPath) {
        matchingDeployment = deployment;
      }
    });
    
    // If no matching deployment found, exit
    if (!matchingDeployment) {
    //   console.log(`No deployment found with FORWARDSLASH: ${currentPath}`);
      setShouldShowLogoutButton(false);
      return;
    }
    
    // console.log(`Found deployment for path ${currentPath}:`, {
    //   title: matchingDeployment.TITLE,
    //   forwardslash: matchingDeployment.FORWARDSLASH
    // });
    
    // Check if this deployment needs auth
    const hasAuthUsers = !!matchingDeployment.AUTH_USERS && matchingDeployment.AUTH_USERS.length > 0;
    const hasUsers = !!matchingDeployment.USERS && matchingDeployment.USERS !== "[]";
    
    // Convert IS_PUBLIC to boolean if it's a string
    let isPublic = matchingDeployment.IS_PUBLIC;
    if (typeof isPublic === 'string') {
      isPublic = isPublic.toLowerCase() === 'true';
    //   console.log(`Converted IS_PUBLIC string "${matchingDeployment.IS_PUBLIC}" to boolean: ${isPublic}`);
    }
    
    // Explicitly check if IS_PUBLIC is false or users exist
    const isPrivate = isPublic === false || hasAuthUsers || hasUsers;
    
    // console.log(`Deployment ${currentPath} details:`, {
    //   title: matchingDeployment.TITLE,
    //   isPublic,
    //   hasAuthUsers,
    //   hasUsers,
    //   isPrivate
    // });
    
    setShouldShowLogoutButton(isPrivate);
  }, [deployments, currentUser, deploymentsLoaded]);
  
//   console.log('Rendering AppContent return', {
//     hasDeployments: Object.keys(deployments).length > 0,
//     currentPath: window.location.pathname,
//     deploymentsLoaded,
//     shouldShowLogoutButton
//   });

  // Show loading state while deployments are being fetched
  if (!deploymentsLoaded) {
    return (
      <div className="App" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Security check...</p>
        </div>
      </div>
    );
  }
  
  return (
    <div className="App" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {shouldShowLogoutButton && <LogoutButton />}
      <div className="Content">
        <AppRoutes deployments={deployments} wait={wait} />
      </div>
      
      <DebugPanel deployments={deployments} wait={wait} />
    </div> 
  );
}

export default AppContent;