// useDocumentMeta.js
import { useEffect } from 'react';

/**
 * Custom hook to manage document title and meta description
 * @param {string} title - The title to set for the document
 * @param {string} description - The description to set in meta tag
 */
const useDocumentMeta = (title, description) => {
  // Update document title
  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  // Update meta description
  useEffect(() => {
    if (description) {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', description);
      } else {
        const newMetaDescription = document.createElement('meta');
        newMetaDescription.setAttribute('name', 'description');
        newMetaDescription.setAttribute('content', description);
        document.head.appendChild(newMetaDescription);
      }
    }
  }, [description]);
};

export default useDocumentMeta;