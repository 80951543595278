// LoginPage.js
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from './App';
import './LoginPage.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [deploymentTitle, setDeploymentTitle] = useState('Vector Vault');
  const { login, error, loading, deployments } = useContext(AuthContext);
  const navigate = useNavigate();
  
  // Get the redirectPath from sessionStorage, not from the login page's location
  const redirectPath = sessionStorage.getItem('intendedPath') || '/';
  const pageKey = sessionStorage.getItem('intendedPageKey') || '';
  
  // Find the deployment title based on the pageKey
  useEffect(() => {
    if (pageKey && deployments && Object.keys(deployments).length > 0) {
      console.log(`Looking for deployment with FORWARDSLASH: ${pageKey}`);
      
      // Find the deployment that matches this pageKey by FORWARDSLASH
      let matchingDeployment = null;
      
      // Loop through all deployments to find the one with matching FORWARDSLASH
      Object.values(deployments).forEach(deployment => {
        if (deployment.FORWARDSLASH === pageKey) {
          matchingDeployment = deployment;
        }
      });
      
      if (matchingDeployment && matchingDeployment.TITLE) {
        setDeploymentTitle(matchingDeployment.TITLE);
        console.log(`Found deployment title for ${pageKey}: ${matchingDeployment.TITLE}`);
      } else {
        console.log(`No deployment title found for ${pageKey}`);
      }
    }
  }, [pageKey, deployments]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      console.log('Login attempt for:', email, 'pageKey:', pageKey);
      console.log('Will redirect to:', redirectPath);
      
      const user = await login(email, password, pageKey);
      
      if (user) {
        console.log('Login successful, redirecting to:', redirectPath);
        
        // Use navigate for redirection
        navigate(redirectPath, { replace: true });
      }
    } catch (err) {
      console.error('Login error:', err);
      // Error is handled in the context
    }
  };
  
  // Modified handler for forgot password link
  const handleForgotPassword = (e) => {
    // We don't need to prevent default since we want to navigate,
    // but we DO want to ensure we don't overwrite the intendedPath and intendedPageKey
    navigate('/forgot-password');
  };
  
  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-header">
          <img 
            src="/logo.png" 
            alt="VectorVault Logo" 
            className="login-logo" 
            onError={(e) => e.target.style.display = 'none'} 
          />
          <h1>{deploymentTitle}</h1>
          <p className="login-subtitle">Sign in to access this page</p>
        </div>
        
        {error && (
          <div className="login-error">
            <i className="error-icon">⚠️</i>
            <span>{error}</span>
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <div className="input-container">
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <div className="input-container">
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="forgot-password-container">
              {/* Replace Link with a button that uses our custom handler */}
              <button 
                type="button"
                className="forgot-password-link text-button"
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </button>
            </div>
          </div>
          
          <button 
            type="submit" 
            className={`login-button ${loading ? 'loading' : ''}`} 
            disabled={loading}
          >
            {loading ? (
              <span className="loader-text">
                <span className="loader"></span>
                Signing in...
              </span>
            ) : (
              'Sign In'
            )}
          </button>
        </form>
        
        <div className="login-footer">
          <p>Powered by <a href="https://vectorvault.io" target="_blank" rel="noopener noreferrer">Vector Vault</a></p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;